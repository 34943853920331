import React, { useEffect, useState } from "react";
import { Box, Typography, Modal, Button, Avatar, Grid } from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SuccessNotifier from "../ToastNotifications/SuccessNotifier";
import BlogModal from "../More/Reuseableblogmodal";
import imagebanner from "../../assets/newbannerhome.jpg";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import Poll from "../More/Poll";
import FAQ from "../../Faq";
import Princesir from "../../assets/princesir.jpg";

function truncateTitle(title, maxLength = 20) {
  return title?.length > maxLength
    ? title.substring(0, maxLength) + "..."
    : title;
}

function NewHome() {
  const location = useLocation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [todayBlogs, setTodayBlogs] = useState([]);
  const [featuredBlogs, setFeaturedBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [videosData, setVideosData] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [open, setOpen] = useState(false);

  const [displayCount, setDisplayCount] = useState({
    todayBlogs: 4,
    featuredBlogs: 4,
    videosData: 4,
  });

  useEffect(() => {
    fetchAllBlog();
    fetchPlaylistVideos();
  }, [location.pathname]);

  const fetchAllBlog = async () => {
    try {
      const category =
        location.pathname.substring(1).toLowerCase() || "cricket";
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/fetch?category=${category}`
      );

      if (data) {
        const today = data.filter((blog) => blog.category === "today");
        const featured = data.filter(
          (blog) => blog.category === "recent" || blog.category === "upcoming"
        );

        if (today.length === 0 && featured.length > 0) {
          const topFeaturedBlogs = featured.slice(0, 3);
          setTodayBlogs(topFeaturedBlogs);
        } else {
          setTodayBlogs(today);
        }

        setFeaturedBlogs(featured);

        setIsSuccess(true);
        setSuccessMessage("Blogs fetched successfully");
        setTimeout(() => {
          setIsSuccess(false);
          setSuccessMessage("");
        }, 1000);
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(error.message);
    }
  };

  const fetchPlaylistVideos = async () => {
    const playlistId = "PLSUatChf34_VgcSDo2kk-ZfbNzOJrBG9U";
    const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
    const maxResults = 50;

    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=${maxResults}&playlistId=${playlistId}&key=${apiKey}`
      );

      const fetchedVideos = response.data.items.map((item) => ({
        id: item.snippet.resourceId.videoId,
        title: item.snippet.title,
        videoId: item.snippet.resourceId.videoId,
        publishedAt: item.snippet.publishedAt,
        thumbnail: item.snippet.thumbnails.high.url,
      }));
      fetchedVideos.sort(
        (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
      );

      setVideosData(fetchedVideos);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const handleOpen = (video) => {
    setSelectedVideo(video);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedVideo(null);
  };

  const handleCardClick = (blog) => {
    setSelectedBlog(blog);
    setShowModal(true);
  };

  const handleShowMore = (section) => {
    setDisplayCount((prevState) => ({
      ...prevState,
      [section]: prevState[section] + 4,
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        backgroundColor: "#999b9f",
        boxShadow: "0 10px 20px rgba(0,0,0,0.12), 0 6px 6px rgba(0,0,0,0.23)",
        position: "relative", // Add position relative to the container
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0 5px 15px rgba(0,0,0,0.3)",
          borderRadius: "50px",
        }}
      >
        {isSuccess && <SuccessNotifier message={successMessage} />}

        <Box
          sx={{
            width: "100%",
            height: { xs: "120px", sm: "300px" },
            marginTop: "80px",
          }}
        >
          <img
            src={imagebanner}
            alt="Banner"
            style={{ height: "400x", width: "100%", objectFit: "contain" }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            padding: "20px",
            backgroundColor: "white",
            marginBottom: "20px",
            borderRadius: "0 0 15px 15px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              marginBottom: "10px",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "24px",
              letterSpacing: "0.5px",
              color: "#333",
            }}
          >
            Trending Blogs
          </Typography>
          <Grid container spacing={2}>
            {todayBlogs.length === 0 && (
              <Typography variant="h6" sx={{ color: "#999" }}>
                No blogs available
              </Typography>
            )}
            {todayBlogs.slice(0, displayCount.todayBlogs).map((blog) => (
              <Grid item xs={6} sm={6} md={3} key={blog.id}>
                <Box
                  sx={{
                    cursor: "pointer",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleCardClick(blog)}
                >
                  <img
                    src={blog.imageUrls}
                    alt={blog.title}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      padding: "10px",
                      fontFamily: "'Poppins', sans-serif",
                      flex: "1 1 auto",
                    }}
                  >
                    {truncateTitle(blog.title)}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          {todayBlogs.length > displayCount.todayBlogs && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => handleShowMore("todayBlogs")}
                sx={{
                  backgroundColor: "#1976d2",
                  color: "white",
                  borderRadius: "20px",
                  padding: "10px 20px",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 600,
                }}
              >
                Show More
              </Button>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            padding: "20px",
            backgroundColor: "white",
            marginBottom: "20px",
            borderRadius: "15px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              marginBottom: "10px",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "24px",
              letterSpacing: "0.5px",
              color: "#333",
            }}
          >
            Videos
          </Typography>
          <Grid container spacing={2}>
            {videosData.slice(0, displayCount.videosData).map((video) => (
              <Grid item xs={6} sm={6} md={3} key={video.videoId}>
                <Box
                  sx={{
                    cursor: "pointer",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleOpen(video)}
                >
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      padding: "10px",
                      fontFamily: "'Poppins', sans-serif",
                      flex: "1 1 auto",
                    }}
                  >
                    {truncateTitle(video.title)}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          {videosData.length > displayCount.videosData && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => handleShowMore("videosData")}
                sx={{
                  backgroundColor: "#1976d2",
                  color: "white",
                  borderRadius: "20px",
                  padding: "10px 20px",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 600,
                }}
              >
                Show More
              </Button>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            padding: "20px",
            backgroundColor: "white",
            marginBottom: "20px",
            borderRadius: "15px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              marginBottom: "10px",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "24px",
              letterSpacing: "0.5px",
              color: "#333",
            }}
          >
            Featured Blogs
          </Typography>
          <Grid container spacing={2}>
            {featuredBlogs.length === 0 && (
              <Typography variant="h6" sx={{ color: "#999" }}>
                No featured blogs available
              </Typography>
            )}
            {featuredBlogs.slice(0, displayCount.featuredBlogs).map((blog) => (
              <Grid item xs={6} sm={6} md={3} key={blog.id}>
                <Box
                  sx={{
                    cursor: "pointer",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleCardClick(blog)}
                >
                  <img
                    src={blog.imageUrls}
                    alt={blog.title}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      padding: "10px",
                      fontFamily: "'Poppins', sans-serif",
                      flex: "1 1 auto",
                    }}
                  >
                    {truncateTitle(blog.title)}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          {featuredBlogs.length > displayCount.featuredBlogs && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => handleShowMore("featuredBlogs")}
                sx={{
                  backgroundColor: "#1976d2",
                  color: "white",
                  borderRadius: "20px",
                  padding: "10px 20px",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 600,
                }}
              >
                Show More
              </Button>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            padding: "20px",
            backgroundColor: "white",
            marginBottom: "20px",
            borderRadius: "15px",
          }}
        >
    
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
            <FAQ />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            padding: "20px",
            backgroundColor: "white",
            marginBottom: "20px",
            borderRadius: "15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              marginBottom: "10px",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "24px",
              letterSpacing: "0.5px",
              color: "#333",
            }}
          >
            About the Author
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          >
            <Avatar
              src={Princesir}
              alt="Prince Kumar"
              sx={{
                width: "200px",
                height: "200px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              Prince Kumar
            </Typography>
            <a
              href="https://www.instagram.com/prince_k369?igsh=Z2ExcnM2N3VyNHNo"
              style={{
                textDecoration: "none",
                color: "#0073e6",
                marginBottom: "10px",
                display: "block",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              More About Prince
            </a>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                maxWidth: "600px",
              }}
            >
              Prince Kumar, the former Owner and CEO at The Sports Prince, is a veteran in the online betting industry and contributes to thesportsprince.com.
            </Typography>
          </Box>
        </Box>

        <BlogModal
          blogDetails={selectedBlog}
          showModal={showModal}
          handleCloseModal={() => setShowModal(false)}
        />

        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "80%", md: "60%" },
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            {selectedVideo && (
              <>
                <Typography variant="h4" component="h2">
                  {selectedVideo.title}
                </Typography>
                <Box
                  sx={{
                    position: "relative",
                    paddingTop: "56.25%",
                    width: "100%",
                    borderRadius: "8px",
                    overflow: "hidden",
                    marginTop: "10px",
                  }}
                >
                  <iframe
                    src={`https://www.youtube.com/embed/${selectedVideo.videoId}`}
                    title={selectedVideo.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  ></iframe>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "20px",
                      padding: "10px 20px",
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Modal>
      </Box>

      {/* Floating Widgets */}
      <Box
        sx={{
          position: "fixed",
          right: "10px",
          top: { xs: "auto", md: "10%" },
          bottom: { xs: "10px", md: "auto" },
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          zIndex: 1000, // Ensure it stays on top
        }}
      >
        {/* Weather Report Card */}
        <Box
          sx={{
            width: "200px",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            display: { xs: "none", md: "block" },
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "10px" }}>
            Weather Report
          </Typography>
          {/* Replace with actual weather data */}
          <Typography variant="body2">27°C, Sunny</Typography>
          <Typography variant="body2">New York, NY</Typography>
        </Box>

        {/* Sports Prince Info */}
        <Box
          sx={{
            width: "200px",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            display: { xs: "none", md: "block" },
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: "10px" }}>
            About Sports Prince
          </Typography>
          <Typography variant="body2">
            The Sports Prince is a leading platform in the online betting industry.
          </Typography>
        </Box>

        {/* WhatsApp and Telegram Icons */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Button
            href="https://chat.whatsapp.com/E90I8wg7e392h418Dgw230"
            target="_blank"
            sx={{
              backgroundColor: "#25D366",
              color: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              minWidth: "auto",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <WhatsAppIcon />
          </Button>
          <Button
            href="https://telegram.me/+HWaRqPIT-ltlYWE1"
            target="_blank"
            sx={{
              backgroundColor: "#0088cc",
              color: "white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              minWidth: "auto",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <TelegramIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NewHome;
