import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import front from "../../src/assets/T6.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FAQ from "../Faq";
import back from "../../src/assets/back.png";
import dhoni from "../../src/assets/dhoni.png";
import banner from "../../src/assets/banner.png";
import DoneIcon from "@mui/icons-material/Done";

import MonitorIcon from "@mui/icons-material/Monitor";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ArticleIcon from "@mui/icons-material/Article";
import SettingsIcon from "@mui/icons-material/Settings";
import "./Course.css";

function Course() {
  const [showFAQ, setShowFAQ] = useState(false);

  const handleToggleFAQ = () => {
    setShowFAQ(!showFAQ); // Toggle FAQ visibility on button click
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "black",
          padding: "20px",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "70%" },
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <Box
            sx={{ width: "100%", textAlign: "center", marginBottom: "10px" }}
          >
            <Typography variant="h3" sx={{ fontWeight: "700", color: "red" }}>
              Fantasy workshop course: <br></br>Become a Fantasy Cricket Expert
            </Typography>
          </Box>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography variant="p" sx={{ color: "#74ff05" }}>
              Learn our insider fantasy contest strategies & become a pro in the
              world of fantasy cricket. This workshop is designed to make you an
              expert in fantasy cricket contests and games.
            </Typography>
          </Box>

          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <img
              src={front}
              alt="sports image"
              style={{
                height: "fit-content",
                width: "80%",
                borderRadius: "30px",

                objectFit: "contain",
              }}
            />
          </Box>
        </Box>

        {/* 30 % wala box */}

        <Box
          sx={{
            width: { xs: "100%", sm: "30%" },
            alignItems: "center",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              border: "1px solid white",
              borderRadius: "10px",
              padding: "15px",
              color: "#74ff05",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Box>
              <a
                href="https://rzp.io/l/UXFX71Hv"
                style={{ textDecoration: "none", color: "#74ff05" }}
              >
                <Typography variant="h5" className="gradient-text">
                  <b> Join Now for ₹99 only</b>
                </Typography>
              </a>
            </Box>

            <a
              href="https://rzp.io/l/UXFX71Hv"
              style={{ textDecoration: "none", color: "#74ff05" }}
            >
              <Typography>Limited Seats (Traning Close Soon)</Typography>
            </a>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              color: "#74ff05",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <span>
                <DoneIcon sx={{ color: "white" }} />
              </span>{" "}
              <Typography sx={{ fontsize: { xs: "0.5", sm: "0.8" } }}>
                completely for Beginners
              </Typography>
            </Box>

            <Box sx={{ display: "flex" }}>
              <span>
                <DoneIcon sx={{ color: "white" }} />
              </span>{" "}
              <Typography sx={{ fontsize: { xs: "0.5", sm: "0.8" } }}>
                24*7 Chat Support
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              border: "1px solid white",
              color: "white",
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{ margin: "5px", fontsize: { xs: "0.2rem", sm: "0.5rem" } }}
            >
              <MonitorIcon />
              <Typography sx={{ color: "#74ff05" }}>
                20+ hours of live session
              </Typography>
            </Box>
            <Box
              sx={{ margin: "5px", fontsize: { xs: "0.2rem", sm: "0.5rem" } }}
            >
              <TipsAndUpdatesIcon />
              <Typography sx={{ color: "#74ff05" }}>
                Learn for Expert
              </Typography>
            </Box>
            <Box
              sx={{ margin: "5px", fontsize: { xs: "0.2rem", sm: "0.5rem" } }}
            >
              <ArticleIcon />
              <Typography sx={{ color: "#74ff05" }}>
                Internation Internship offer
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* second box */}
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Typography variant="h4" sx={{ fontWeight: "700", color: "red" }}>
            What You will learn in this workshop ?
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            // padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "95%",
              margin: "10px",
              padding: "10px",
              backgroundColor: "black",
              borderRadius: "15px",
              color: "white",
            }}
          >
            <Typography variant="h5">
              <span>
                <CheckCircleIcon />
              </span>{" "}
              The explorers and insider information on each platform
            </Typography>
            <Typography
              variant="p"
              sx={{ textAlign: "justify", fontSize: "1rem" }}
            >
              Learn the concept Of fantasy cricket and understand the
              intricacies Of the game. It also helps an individual choose the
              platform where he can play any contest of his choice
            </Typography>
          </Box>

          <Box
            sx={{
              width: "95%",
              margin: "10px",
              padding: "10px",
              backgroundColor: "black",
              borderRadius: "15px",
              color: "white",
            }}
          >
            <Typography variant="h5">
              <span>
                <CheckCircleIcon />
              </span>{" "}
              Research and team preparation process
            </Typography>
            <Typography variant="p">
              The process involves knowing & understanding the different ways of
              making a research to get the information and details regarding
              player performance, comparison and other important stats &
              analysis., It also involves carrying out analytical studies to
              collect data for the upcoming matches.
            </Typography>
          </Box>

          <Box
            sx={{
              width: "95%",
              margin: "10px",
              padding: "10px",
              backgroundColor: "black",
              borderRadius: "15px",
              color: "white",
            }}
          >
            <Typography variant="h5">
              <span>
                <CheckCircleIcon />
              </span>{" "}
              Stay profitable & create a long term career
            </Typography>
            <Typography variant="p">
              How to stay profitable in the long run and how an individual can
              make fantasy cricket his long term career are some of the
              important discussions in this section
            </Typography>
          </Box>

          <Box
            sx={{
              width: "95%",
              margin: "10px",
              padding: "10px",
              backgroundColor: "black",
              borderRadius: "15px",
              color: "white",
            }}
          >
            <Typography variant="h5">
              <span>
                <CheckCircleIcon />
              </span>{" "}
              Complete walk through of selecting the Best 11
            </Typography>
            <Typography variant="p">
              Here one gets to know how to select best 11 on the context of
              matches and past performances of the players to get the maximum
              out Of fantasy contests.
            </Typography>
          </Box>

          <Box
            sx={{
              width: "95%",
              margin: "10px",
              padding: "10px",
              backgroundColor: "black",
              borderRadius: "15px",
              color: "white",
            }}
          >
            <Typography variant="h5">
              <span>
                <CheckCircleIcon />
              </span>{" "}
              Understanding player roles and impact
            </Typography>
            <Typography variant="p">
              Player roles and their impact upon the selection Of teams is
              discussed here to help individual get through the complexities of
              fantasy contests
            </Typography>
          </Box>

          <Box
            sx={{
              width: "95%",
              margin: "10px",
              padding: "10px",
              backgroundColor: "black",
              borderRadius: "15px",
              color: "white",
            }}
          >
            <Typography variant="h5">
              <span>
                <CheckCircleIcon />
              </span>{" "}
              Mastering the art of captain and vice-captain selection
            </Typography>
            <Typography variant="p">
              The art of selecting captain and vice-captain is an important art
              as it impacts team the most and accordingly the output in the
              fantasy contests.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Third box */}
      <Box
        sx={{
          width: "100%",
          backgroundColor: "black",
          color: "white",
          padding: "20px",
        }}
      >
        <Box sx={{ width: "100%", textAlign: "center", marginBottom: "10px" }}>
          <Typography variant="h5" sx={{ fontWeight: "700", color: "red" }}>
            Who Is This Workshop Designed For?
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: "700", color: "#74ff05" }}>
            EACH & EVERY CRICKET ENTHUSIASTS
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              border: "1px solid black",
              margin: "10px",
              height: { xs: "40vh", sm: "30vh" },
              width: "70vw",
              textAlign: "center",
              borderRadius: "15px",
              padding: "10px",
              backgroundColor: "#2F3C67",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "700", color: "white" }}>
              Beginners
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { xs: "0.5", sm: "0.8rem" },
                color: "white",
              }}
            >
              The workshop acts as foundation course for individuals who are
              beginners in fantasy games and want an in-depth or intermediate
              knowledge to excel in the art of fantasy contests.
            </Typography>
          </Box>
          <Box
            sx={{
              border: "1px solid black",
              margin: "10px",
              height: { xs: "40vh", sm: "30vh" },
              width: "70vw",
              textAlign: "center",
              borderRadius: "15px",
              padding: "10px",
              backgroundColor: "#2F3C67",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "700", color: "white" }}>
              Intermediate
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { xs: "0.5", sm: "0.8rem" },
                color: "white",
              }}
            >
              The workshop provides advanced insights and strategies for an
              average or intermediate level player to gain necessary skill-sets
              to advance to professional level in fantasy games and contests
            </Typography>
          </Box>
          <Box
            sx={{
              border: "1px solid black",
              margin: "10px",
              height: { xs: "40vh", sm: "30vh" },
              width: "70vw",
              textAlign: "center",
              borderRadius: "15px",
              padding: "10px",
              backgroundColor: "#2F3C67",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "700", color: "white" }}>
              Professional
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: { xs: "0.5", sm: "0.8rem" },
                color: "white",
              }}
            >
              The workshop acts as a refresher and skill promoter for even those
              who are professional players and performers in fantasy games and
              contests. The workshop aims to guide through insider and share
              necessary tips and latest information to stay ahead in the
              competition
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* forth box */}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          padding: "20px",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "30%" },
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box>
            <img
              src={back}
              alt="sports image"
              style={{
                height: "50vh",
                width: "80%",
                borderRadius: "30px",
                marginTop: "50px",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: { xs: "100%", sm: "70%" },
            alignContent: "center",
            textAlign: "center",
          }}
        >
          <Box
            sx={{ width: "100%", textAlign: "center", marginBottom: "10px" }}
          >
            <Typography variant="h5" sx={{ fontWeight: "700" }}>
              Prince Kumar
            </Typography>

            <Typography sx={{ fontWeight: "600", fontsize: "0.6rem" }}>
              Performance Analyst, SRH, ex ESPN Cricinfo
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <img
              style={{ width: "100%", maxWidth: "270px" }}
              src={banner}
              alt="banner"
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", margin: "5px" }}>
              <span>
                <CheckCircleIcon />
              </span>
              <Typography
                sx={{
                  fontsize: { xs: "0.5rem", sm: "0.8rem" },
                  textAlign: "justify",
                }}
              >
                Team Performance Analyst for SunRisers Hyderabad, IPL 2023 &
                2024
              </Typography>
            </Box>

            <Box sx={{ display: "flex", margin: "5px" }}>
              <span>
                <CheckCircleIcon />
              </span>{" "}
              <Typography
                sx={{
                  fontsize: { xs: "0.5rem", sm: "0.8rem" },
                  textAlign: "justify",
                }}
              >
                Team Performance Analyst for the two-time champions SunRisers
                Eastern cape , SA20 League 2023 & 2024
              </Typography>
            </Box>
            <Box sx={{ display: "flex", margin: "5px" }}>
              <span>
                <CheckCircleIcon />
              </span>{" "}
              <Typography
                sx={{
                  fontsize: { xs: "0.5rem", sm: "0.8rem" },
                  textAlign: "justify",
                }}
              >
                Worked as a Sr. Cricket Analyst at ESPN Cricinfo
              </Typography>
            </Box>
            <Box sx={{ display: "flex", margin: "5px" }}>
              <span>
                <CheckCircleIcon />
              </span>{" "}
              <Typography
                sx={{
                  fontsize: { xs: "0.5rem", sm: "0.8rem" },
                  textAlign: "justify",
                }}
              >
                Worked with international teams (countries and franchises) and
                helped them use advanced analytics to create a winning edge
              </Typography>
            </Box>

            <Box sx={{ display: "flex", margin: "5px" }}>
              <span>
                <CheckCircleIcon />
              </span>{" "}
              <Typography
                sx={{
                  fontsize: { xs: "0.5rem", sm: "0.8rem" },
                  textAlign: "justify",
                }}
              >
                Consulted the Champion West Indies Cricket Team for the T 20
                world cup, 2016
              </Typography>
            </Box>

            <Box sx={{ display: "flex", margin: "5px" }}>
              <span>
                <CheckCircleIcon />
              </span>{" "}
              <Typography
                sx={{
                  fontsize: { xs: "0.5rem", sm: "0.8rem" },
                  textAlign: "justify",
                }}
              >
                Worked with the Indian Cricket Team: Led a team of IO members
                and created solutions and strategies for our team
              </Typography>
            </Box>

            <Box sx={{ display: "flex", margin: "5px" }}>
              <span>
                <CheckCircleIcon />
              </span>{" "}
              <Typography
                sx={{
                  fontsize: { xs: "0.5rem", sm: "0.8rem" },
                  textAlign: "justify",
                }}
              >
                Represented RCB at the IPL Auction in 2015
              </Typography>
            </Box>

            <Box sx={{ display: "flex", margin: "5px" }}>
              <span>
                <CheckCircleIcon />
              </span>{" "}
              <Typography
                sx={{
                  fontsize: { xs: "0.5rem", sm: "0.8rem" },
                  textAlign: "justify",
                }}
              >
                Speaker at 9th Annual MIT Sloan Sports Analytics Conference, MIT
                Boston
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* fifth box */}

      <Box
        sx={{
          width: "100%",
          backgroundColor: "black",
          padding: "20px",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            color: "red",
            fontWeight: "700",

            marginBottom: "10px",
          }}
        >
          <Typography variant="h5">
            Enroll before May 08, 2024 to unlock 2 extra bonuses worth e5500 for
            FREE!
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            color: "white",
          }}
        >
          <img src={dhoni} alt="ipl" style={{ borderRadius: "20px" }} />
          <Box
            sx={{
              marginLeft: "20px",
              alignContent: "center",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                border: "1px solid white",
                padding: "10px",
                justifyContent: "space-between",
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Typography>Expert Guidence</Typography>
              <CheckCircleIcon sx={{ color: "#74ff05" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                border: "1px solid white",
                padding: "10px",
                justifyContent: "space-between",
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Typography>Practical</Typography>
              <CheckCircleIcon sx={{ color: "#74ff05" }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                border: "1px solid white",
                padding: "10px",
                justifyContent: "space-between",
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Typography>Community</Typography>
              <CheckCircleIcon sx={{ color: "#74ff05" }} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <Button
          onClick={handleToggleFAQ}
          sx={{
            backgroundColor: "#564AE4",
            textAlign: "center",
            padding: "10px",
            borderRadius: "10px",
            color: "white",
          }}
        >
          FAQ
        </Button>
      </Box>

      {showFAQ && (
        <Box
          sx={{
            // backgroundColor: "#E1E6FB",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FAQ />
        </Box>
      )}
    </Box>
  );
}

export default Course;
