import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import { BorderAllRounded } from "@mui/icons-material";
const styles = {
  footer: {
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    // position: "fixed",
    bottom: 0,
    left: 0,
    width: { xs: "100%", md: "100%" },
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
};

function Footer() {
  const location = useLocation();

  if (location.pathname === "/course") {
    return null;
  }
  return (
    <Box sx={styles.footer}>
      <Box
        sx={{
          backgroundColor: "#252525",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            width: "20%",
            // backgroundColor: "yellow",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Box sx={{ width: "100%", maxHeight: "150px", objectFit: "cover" }}>
            <img src={logo} alt="our logo" style={{ width: "150px" }} />
          </Box>
          <p style={{ color: "white", textAlign: "left" }}>
            Hello sports freeks,it's exiting to <br />
            welcome you to the exciting sport <br />
            through our website
          </p>
        </Box>
        <Box sx={{ width: "20%", padding: "10px" }}>
          <Typography variant="h6" sx={{ textAlign: "justify" }}>
            TOP FEATURES
          </Typography>
          <p style={{ margin: "0 0 5px 0", textAlign: "justify" }}>
            Prediction Center
          </p>
          <p style={{ margin: "0 0 5px 0", textAlign: "justify" }}>
            Fantasy Center
          </p>
          <p style={{ margin: "0 0 5px 0", textAlign: "justify" }}>
            Sports News
          </p>
          <p style={{ margin: "0 0 0 0", textAlign: "justify" }}>
            Sports Videos
          </p>
        </Box>
        <Box sx={{ width: "20%", padding: "10px" }}>
          <Typography variant="h6" sx={{ textAlign: "justify" }}>
            TOP SERIES
          </Typography>
          <p style={{ margin: "0 0 5px 0", textAlign: "justify" }}>IPL</p>
          <p style={{ margin: "0 0 5px 0", textAlign: "justify" }}>BBL</p>
          <p style={{ margin: "0 0 5px 0", textAlign: "justify" }}>
            Premier League
          </p>
          <p style={{ margin: "0 0 0 0", textAlign: "justify" }}>La Liga</p>
          <p style={{ margin: "0 0 0 0", textAlign: "justify" }}>
            US Open League
          </p>
        </Box>
        <Box sx={{ width: "20%", padding: "10px" }}>
          <Typography variant="h6" sx={{ textAlign: "justify" }}>
            IMPORTANT LINKS
          </Typography>
          <p style={{ margin: "0 0 5px 0", textAlign: "justify" }}>
            <a
              href="/about-us"
              style={{ textDecoration: "none", color: "white" }}
            >
              About
            </a>
          </p>
          <p style={{ margin: "0 0 5px 0", textAlign: "justify" }}>
            <a
              href="/contactus"
              style={{ textDecoration: "none", color: "white" }}
            >
              Contact Us
            </a>
          </p>
          <p style={{ margin: "0 0 5px 0", textAlign: "justify" }}>
            <a
              href="/course"
              style={{ textDecoration: "none", color: "white" }}
            >
              Career
            </a>
          </p>

          <p style={{ margin: "0 0 0 0", textAlign: "justify" }}>
            <a
              href="/term-and-condition"
              style={{ textDecoration: "none", color: "white" }}
            >
              Disclaimer
            </a>
          </p>
          <p style={{ margin: "0 0 0 0", textAlign: "justify" }}>
            <a
              href="/privacy-policy"
              style={{ textDecoration: "none", color: "white" }}
            >
              Privacy Policy{" "}
            </a>
          </p>
        </Box>
      </Box>

      <Typography>
        &copy;Copyrignt @2023 - 2024 The Sports Prince All Rights Reserved
      </Typography>
    </Box>
  );
}

export default Footer;
