import React, { useState, useEffect } from "react";
import {
  Modal,
  Card,
  Box,
  Typography,
  Button,
  CardContent,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar";
import BottomNavigationBar from "../BottomNavigationBar";
import axios from "axios";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, CardMedia, CardActionArea } from "@mui/material";
import Poll from "./Poll";
const BlogModal = ({ showModal, handleCloseModal, blogDetails }) => {
  const theme = useTheme();
  const isMobilenew = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMobile, setIsMobile] = useState(false);
  const [videoData, setVideosData] = useState([]);
  const [newData, setNewsData] = useState([]);
  const [showPollForm, setShowPollForm] = useState(false);

  const handleScrollToSection = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href").slice(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleShare = async (blog) => {
    const blogUrl = `${window.location.origin}/blog?blogId=${blog._id}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: blog.title,
          url: blogUrl,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(blogUrl);
        alert("Blog URL copied to clipboard!");
      } catch (error) {
        console.error("Failed to copy URL:", error);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Assuming 768px is your mobile breakpoint
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //   const { title, posted, firstImage, blogDescription, matchtitle, venue, date, WeatherReport, PitchReport, TeamA, Team1Name, TeamB, Team2Name, PredictionResult, TeamNews, Squad, videoId } = blogDetails;
  const {
    TeamA,
    TeamB,
    category,
    createdAt,
    date,
    description,
    imageUrls,
    live,
    matchtitle,
    pitchreport,
    predictionresult,
    squad,
    teamaname,
    teambname,
    teamnews,
    title,
    user,
    venue,
    weatherreport,
    _id,
    videoUrl,
  } = blogDetails;

  const videoIdMatch = videoUrl?.match(
    /(?:live\/|embed\/|v\/|watch\?v=|.*[?&]v=)([^\s?&]+)/
  );
  const videoId = videoIdMatch && videoIdMatch[1];

  useEffect(() => {
    const fetchPlaylistVideos = async () => {
      const playlistId = "PLSUatChf34_VgcSDo2kk-ZfbNzOJrBG9U"; // Replace with your playlist ID
      const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
      const maxResults = 50;

      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=${maxResults}&playlistId=${playlistId}&key=${apiKey}`
        );

        const fetchedVideos = response.data.items.map((item) => ({
          id: item.snippet.resourceId.videoId,
          title: item.snippet.title,
          videoId: item.snippet.resourceId.videoId,
          publishedAt: item.snippet.publishedAt,
        }));
        fetchedVideos.sort(
          (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
        );
        setVideosData(fetchedVideos);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    const fetchLatestNews = async () => {
      try {
        const response = await axios.get(
          `https://newsapi.org/v2/top-headlines?country=in&category=sports&sortBy=publishedAt&apiKey=${process.env.REACT_APP_NEW_APIKEY}`
        );

        setNewsData(response?.data.articles);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPlaylistVideos();
    fetchLatestNews();
  }, []);

  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100vh",
        overflowY: "auto",
        overflowX: "auto",
       width:"100%",
      
      }}
    >
      <Card
        sx={{ backgroundColor: "#F5F7F8", color: "white",width:"100%",}}
      >
        <Navbar />
        <Box
          sx={{
            position: "absolute",
            top: "70px",
            right: "10px",
            display: "flex",
            gap: "10px",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "70px",
              right: "10px",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleShare(blogDetails);
            }}
          >
            <ShareIcon sx={{ color: "red" }} />
          </IconButton>

          <IconButton
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleCloseModal();
            }}
          >
            <CloseIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>

        <CardContent
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
             padding:0,
            marginTop: "80px",
          }}
        >
          <Box
            sx={{
              width: isMobilenew ? "100%" : "100%",
              backgroundColor: "#222222",
              padding: "20px",
            }}
          >
            {/* box that contain heading,image,preview,match info,weather report pitch report */}
            <Typography
              variant="h4"
              component="h5"
              sx={{
                fontWeight: "bold",
                color: "white",
                // marginBottom: "5px",
                textAlign: "start",
                fontSize: isMobilenew ? "1rem" : "1.8rem",
              }}
            >
              {title}
            </Typography>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography sx={{ fontSize: isMobile ? "5px" : "10px" }}>
                Date & Time :{createdAt}
              </Typography>
            </Box>
            <Box sx={{width:"100%"}}>
              <img
                src={imageUrls}
                alt="blogimage"
                style={{
                  height: "auto",
                  width: "50%",
                }}
              />
            </Box>

            <Box sx={{width:"100%",height:"10px",display:"flex",justifyContent:"space-between"}}>
            <Box sx={{width:"50%", backgroundColor:"#ee4036" ,height:"10px"}}/>
            <Box sx={{width:"50%",backgroundColor:"#37b34a",height:"10px"}}/>


           

            </Box>

            
            <Poll blogId={_id} />

      
            <Box
              sx={{
                textAlign: "justify",
                padding: "15px",
                border: "2px solid white",
                marginBottom: "10px",
              }}
            >
              <Typography
                id="Match_info"
                variant="h5"
                sx={{ marginBottom: "10px" }}
              >
                Match Info
              </Typography>
              <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                <li>
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                  >
                    Match Title :
                  </Typography>{" "}
                  {matchtitle}
                </li>
                <li>
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                  >
                    Vanue :
                  </Typography>{" "}
                  {venue}
                </li>

                <li>
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                  >
                    Date :
                  </Typography>{" "}
                  {date}
                </li>
              </ul>
            </Box>

            {/* preview */}
            <Box
              id="preview"
              sx={{
                textAlign: "justify",
                padding: "15px",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "600" }}>
                Preview
              </Typography>

              <p dangerouslySetInnerHTML={{ __html: description }} />
            </Box>

            {/* weather report */}

            {weatherreport && (
              <Box
                id="weather_report"
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                  color: "#fff", // White text for better contrast
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Weather Report
                </Typography>
                <Typography>{weatherreport}</Typography>
              </Box>
            )}

            {/* pitch report */}

            {pitchreport && (
              <Box
                id="pitch_report"
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                  color: "#fff", // White text for better contrast
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Pitch Report
                </Typography>
                <Typography>{pitchreport}</Typography>
              </Box>
            )}

            {/* playing 11 */}
            {TeamA && teamaname && (
              <Box
                id="playing_11"
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                  color: "white",
                  border: "2px solid white",
                  marginBottom: "10px", // White text for better contrast
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Playing 11/Probably Playing 11 {teamaname}
                </Typography>
                <Typography>{TeamA}</Typography>
              </Box>
            )}
            {TeamB && teambname && (
              <Box
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                  color: "white",
                  border: "2px solid white",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Playing 11/Probably Playing 11 {teambname}
                </Typography>
                <Typography>{TeamB}</Typography>
              </Box>
            )}

            {/* prediction result */}
            {predictionresult && (
              <Box
                id="prediction_result"
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                  color: "white", // White text for better contrast
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px", fontWeight: "600" }}
                >
                  Prediction Result
                </Typography>
                <Typography>{predictionresult}</Typography>
              </Box>
            )}

            {/* Team news */}

            {teamnews && (
              <Box
                id="team_news"
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                  color: "white", // White text for better contrast
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px", fontWeight: "600" }}
                >
                  Team News
                </Typography>
                <Typography>{teamnews}</Typography>
              </Box>
            )}

            {/* squad */}
            {squad && (
              <Box
                id="squad"
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                  color: "white", // White text for better contrast
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "10px", fontWeight: "600" }}
                >
                  Squad
                </Typography>
                <Typography>{squad}</Typography>
              </Box>
            )}

            {/* video player */}

            <Box
              id="squad"
              sx={{
                textAlign: "justify",
                padding: "15px",
                borderRadius: "10px", // Rounded corners
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                color: "white",
                height: "20rem",
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}`}
                // title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                // style={{ border: 0, boxSizing: "content-box" }}
              ></iframe>
            </Box>
          </Box>
        </CardContent>
        <Button
          variant="contained"
          onClick={handleCloseModal}
          sx={{
            marginY: 8,
            width: "200px",
            height: "50px",
            backgroundColor: "red",
          }}
        >
          Close
        </Button>

        {isMobilenew ? <BottomNavigationBar /> : <Footer />}
      </Card>
    </Modal>
  );
};

export default BlogModal;
