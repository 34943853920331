import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const FAQ = () => {
  const faqs = [
    {
      question:
        "Do I need any prior experience in cricket or fantasy sports to go with the workshop?",
      answer:
        "No prerequisite as such is needed to learn. The workshop is designed to cater to the needs of all types of learners – from beginners to professionals. Hence, no previous experience is required.",
    },
    {
      question: "What is the total course duration?",
      answer:
        "The course module is comprehensive yet flexible. One can learn and attend the course workshop as per convenience and suitability. The course curriculum and workshop are customizable.",
    },
    {
      question: "What support is available after learning?",
      answer:
        "A dedicated post-workshop online support is available for the learners and attendees of the workshop.",
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleExpand = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(-1); // Collapse if already expanded
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <Box sx={{ width: "100%", marginBottom: "30px" }}>
      <Typography
        variant="h5"
        sx={{
          color: "#333",
          fontWeight: "bold",
          fontFamily: "'Poppins', sans-serif",
          marginBottom: "20px",
        }}
        gutterBottom
      >
        Frequently Asked Questions (FAQs)
      </Typography>
      {faqs.map((faq, index) => (
        <Box
          key={index}
          sx={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            marginBottom: "16px",
            backgroundColor: "#f9f9f9",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px 16px",
              cursor: "pointer",
              backgroundColor: "#0073e6",
              color: "white",
            }}
            onClick={() => handleExpand(index)}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "'Poppins', sans-serif",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {faq.question}
            </Typography>
            {expandedIndex === index ? <ExpandLess /> : <ExpandMore />}
          </Box>
          {expandedIndex === index && (
            <Box
              sx={{
                padding: "16px",
                backgroundColor: "white",
                color: "#333",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "14px",
                }}
              >
                {faq.answer}
              </Typography>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default FAQ;
