import React, { useEffect, useState } from "react";
import { Modal, TextField, Button, Typography, MenuItem } from "@mui/material";
import axios from "axios";
import { ChatState } from "../../Context/ChatProvider";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { Cloudinary } from "cloudinary-core";
import "./AddBlogModal.css"; // Import your CSS file here

const cloudinary = new Cloudinary({ cloud_name: "dcpcbt2hr", secure: true });

const editorOptions = {
  height: 200,
  buttonList: [
    ["undo", "redo"],
    ["removeFormat"],
    ["bold", "underline", "italic", "fontSize"],
    ["fontColor", "hiliteColor"],
    ["align", "horizontalRule", "list"],
    ["table", "link", "image", "imageGallery"],
    ["showBlocks", "codeView"]
  ],
  fontSize: [12, 14, 16, 18, 20],
  colorList: [
    [
      "#828282",
      "#FF5400",
      "#676464",
      "#F1F2F4",
      "#FF9B00",
      "#F00",
      "#fa6e30",
      "#000",
      "rgba(255, 153, 0, 0.1)",
      "#FF6600",
      "#0099FF",
      "#74CC6D",
      "#FF9900",
      "#CCCCCC"
    ]
  ]
};

function AddBlogModal({ open, onClose }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [error, setError] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [matchtitle, setMatchtitle] = useState("");
  const [venue, setVenue] = useState("");
  const [date, setDate] = useState("");
  const [live, setLive] = useState("");
  const [loggedInuser, setLoggedInUser] = useState(null);
  const [weatherreport, setWeatherReport] = useState("");
  const [pitchReport, setPitchReport] = useState("");
  const [squad, setSquad] = useState("");
  const [teamnews, setTeamnews] = useState("");
  const [predictionresult, setPredictionResult] = useState("");
  const [playerNames, setPlayerNames] = useState(Array(11).fill(""));
  const [teama, setTeamA] = useState("");
  const [teamb, setTeamB] = useState("");
  const [teamaname, setTeamAName] = useState("");
  const [teambname, setTeamBName] = useState("");
  const [category, setCategory] = useState(""); 
  const [blogcategory, setBlogCategory] = useState(""); 

  const { user, setUser } = ChatState();
  useEffect(() => {
    if (!user) {
      try {
        const userInfo = localStorage.getItem("userData");
        if (userInfo) {
          const userData = JSON.parse(userInfo);
          setUser(userData);
        }
      } catch (error) {
        console.error("Error parsing user data from local storage:", error);
      }
    }
  }, [user]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "e3ztc2ke");

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/dcpcbt2hr/image/upload`,
        formData
      );
      const url = response.data.secure_url;
      setImageUrls(url);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (
        !title ||
        !description ||
        !user ||
        !imageUrls.length ||
        !videoUrl ||
        !matchtitle ||
        !category
      ) {
        console.error("All fields are required");
        if (
          !title ||
          !description ||
          imageUrls.length === 0 ||
          !videoUrl ||
          !matchtitle ||
          !category
        ) {
          alert("Please fill the Required Field");
        } else {
          alert("Please refresh the Page, user is not found");
        }
        return;
      }

      const extractVideoId = (url) => {
        const videoIdMatch = url.match(
          /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|.*[?&]v=))([^&?/\s]+)/
        );
        return videoIdMatch && videoIdMatch[1];
      };

      const videoId = extractVideoId(videoUrl.trim());

      const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;

      const postData = {
        title: title,
        description: description,
        user: user._id,
        imageUrls: imageUrls,
        videoUrl: embedUrl,
        matchtitle: matchtitle,
        venue: venue,
        date: date,
        live: live,
        weatherReport: weatherreport,
        pitchReport: pitchReport,
        Squad: squad,
        TeamNews: teamnews,
        PredictionResult: predictionresult,
        TeamA: teama,
        TeamB: teamb,
        teamaname: teamaname,
        teambname: teambname,
        category: category,
        blogcategory: blogcategory,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/create`,
        postData
      );
      
      alert("You have successfully posted the Blog");

      setTitle("");
      setDescription("");
      setImageUrls([]);
      setVideoUrl("");
      setMatchtitle("");
      setVenue("");
      setDate("");
      setLive("");
      setWeatherReport("");
      setPitchReport("");
      setSquad("");
      setTeamnews("");
      setPredictionResult("");
      setTeamA("");
      setTeamB("");
      setTeamBName("");
      setTeamAName("");
      setCategory("");
      setBlogCategory("");

      onClose();
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleChange = (content) => {
    setDescription(content);
  };

  const extractAndStyleTable = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const table = doc.querySelector('table');
    
    if (table) {
      table.classList.add('styled-table');
    }

    return {
      table: table ? table.outerHTML : '',
      rest: doc.body.innerHTML.replace(table ? table.outerHTML : '', '')
    };
  };

  const { table, rest } = extractAndStyleTable(description);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          width: "80%",
          backgroundColor: "#fff",
          padding: 20,
          height: "90vh",
          overflowY: "auto",
        }}
      >
        <TextField
          label="Title *"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginBottom: 10 }}
        />
  
        <SunEditor
          setOptions={editorOptions}
          onChange={handleChange}
        />

        <div style={{ marginBottom: 10 }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ marginBottom: 10 }}
          />
        </div>
        <TextField
          label="Trending Video URL's *"
          fullWidth
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          style={{ marginBottom: 10 }}
        />

        <div>
        <TextField
          select
          label="Category *"
          fullWidth
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          style={{ marginBottom: 10 }}
        >
          <MenuItem value="upcoming">Upcoming</MenuItem>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="recent">Recent</MenuItem>
        </TextField>

        <TextField
          select
          label="Blog_Category *"
          fullWidth
          value={blogcategory}
          onChange={(e) => setBlogCategory(e.target.value)}
          style={{ marginBottom: 10 }}
        >
          <MenuItem value="football">Football</MenuItem>
          <MenuItem value="cricket">Cricket</MenuItem>
          <MenuItem value="tennis">Tennis</MenuItem>
        </TextField>

          <TextField
            label="Title of the Match e.g KKR vs CSK *"
            fullWidth
            value={matchtitle}
            onChange={(e) => setMatchtitle(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <Typography variant="h5">Fill these details</Typography>
          <TextField
            label="Venue of the Match"
            fullWidth
            value={venue}
            onChange={(e) => setVenue(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Date and Time of the Match e.g  Friday, April 26, 7:30 pm"
            fullWidth
            value={date}
            onChange={(e) => setDate(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Live Broadcast and Streaming Details"
            fullWidth
            value={live}
            onChange={(e) => setLive(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Weather Report"
            fullWidth
            value={weatherreport}
            onChange={(e) => setWeatherReport(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Pitch Report"
            fullWidth
            value={pitchReport}
            onChange={(e) => setPitchReport(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Prediction Result"
            fullWidth
            value={predictionresult}
            onChange={(e) => setPredictionResult(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Squad"
            fullWidth
            value={squad}
            onChange={(e) => setSquad(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Team 1 Name"
            fullWidth
            value={teamaname}
            onChange={(e) => setTeamAName(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Team 2 Name"
            fullWidth
            value={teambname}
            onChange={(e) => setTeamBName(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Team News"
            fullWidth
            value={teamnews}
            onChange={(e) => setTeamnews(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Team 1 Player 11"
            fullWidth
            value={teama}
            onChange={(e) => setTeamA(e.target.value)}
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Team 2 Player 11"
            fullWidth
            value={teamb}
            onChange={(e) => setTeamB(e.target.value)}
            style={{ marginBottom: 10 }}
          />
        </div>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <div dangerouslySetInnerHTML={{ __html: table }} />
        <div dangerouslySetInnerHTML={{ __html: rest }} />
      </div>
    </Modal>
  );
}

export default AddBlogModal;
