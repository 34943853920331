import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorNotifier from "./ToastNotifications/ErrorNotifier";
import SuccessNotifier from "./ToastNotifications/SuccessNotifier";
import PersonIcon from "@mui/icons-material/Person";
import logo from "../assets/logo.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";

const navItems = ["Cricket", "Football", "Tennis", "More"];

const Navbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchuserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/login/success`,
        { withCredentials: true }
      );
      setUserData(response.data);
      localStorage.setItem("userData", JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchuserData();

    // Set a timeout to clear user data after 24 hours
    const clearUserDataTimeout = setTimeout(() => {
      localStorage.removeItem("userData");
    }, 24 * 60 * 60 * 1000);

    return () => {
      // Clear the timeout to avoid memory leaks
      clearTimeout(clearUserDataTimeout);
    };
  }, []);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleThemeChange = () => {
    if (userDataAvailable) {
      navigate(`/profile`);
    } else {
      navigate(`/login`);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userData");
    setUserData(null);
    setIsSuccess(true);
    setSuccessMessage("Logged Out successfully");
    setTimeout(() => {
      setIsSuccess(false);
      setSuccessMessage("");
      // Navigate to "/login" after updating state and displaying success message
      navigate("/login");
    }, 3000);
  };

  const userDataAvailable = localStorage.getItem("userData");

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (location.pathname === "/course") {
    return null; // Return null if the current URL is "/course"
  }

  return (
    <Box sx={{ display: "flex" }}>
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}

      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      <CssBaseline />

      <AppBar component="nav">
      <Box
        sx={{
          backgroundColor: "#999b9f",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px 10px",
          height: "30px",
        }}
      >
      <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "black",
            fontStyle: "italic",
            display: "flex",
          }}
        >
          <Box component="span" sx={{ color: "#ff0000" }}>
            Join
          </Box>
          &nbsp;
          <Box component="span" sx={{ color: "#00009b" }}>
            Our Family
          </Box>
        </Typography>
        <Box>
          <IconButton
            component="a"
            href="https://chat.whatsapp.com/E90I8wg7e392h418Dgw230"
            target="_blank"
            sx={{ color: "green", marginRight: "10px" }}
          >
            <WhatsAppIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://telegram.me/+HWaRqPIT-ltlYWE1"
            target="_blank"
            sx={{ color: "#0088cc" }}
          >
            <TelegramIcon />
          </IconButton>
        </Box>
      </Box>

        <Toolbar
          sx={{ backgroundColor: "#fbfbfb", justifyContent: "space-between" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="/">
              <img
                src={logo}
                style={{
                  width: "170px", // Adjust the width as needed
                  height: "auto", // Maintain aspect ratio
                  marginRight: "10px",
                  marginTop: "10px", // Add margin to separate from text
                }}
                alt="Logo"
              />
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            {navItems.map((item) => (
              <Button
                key={item}
                component={Link}
                to={item === "Cricket" ? "/" : `/${item.toLowerCase()}`}
                sx={{
                  color: "white",
                  fontWeight: "500",
                  backgroundColor: "red",
                  borderRadius: "30px",
                  margin: "5px",
                  fontWeight: "700",
                  "&:hover": {
                    backgroundColor: "black", // Change background color to blue on hover
                    color: "white", // Change text color to white on hover
                  },
                }}
              >
                {item}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!userDataAvailable && (
              <Button
                component={Link}
                to={`/login`}
                sx={{
                  color: "red",
                  fontWeight: "700",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "red", // Change background color to blue on hover
                    color: "white", // Change text color to white on hover
                  },
                }}
              >
                Login
              </Button>
            )}

            {userDataAvailable && (
              <Button
                onClick={handleLogout}
                sx={{
                  color: "red",
                  fontWeight: "700",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "red", // Change background color to blue on hover
                    color: "white", // Change text color to white on hover
                  },
                }}
              >
                LogOut
              </Button>
            )}
            <IconButton onClick={handleThemeChange} sx={{ color: "inherit" }}>
              <PersonIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;
