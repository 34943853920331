import React, { useState, useEffect } from "react";
import { Button, List, ListItem } from "@mui/material";
import AddBlogModal from "./AddBlogModal";
import EditBlogModal from "./UpdateBlog";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link as ScrollLink, Element } from "react-scroll";
import ShareIcon from "@mui/icons-material/Share";

import sanitizeHtml from "sanitize-html";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActionArea,
  Modal,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { ChatState } from "../../Context/ChatProvider";
import SuccessNotifier from "../ToastNotifications/SuccessNotifier";
import ErrorNotifier from "../ToastNotifications/ErrorNotifier";
import circularProgressClasses from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactPlayer from "react-player";

/***Table container here */
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Video from "../Video/Video";
import Navbar from "../Navbar";
import News from "../News/News";
import Footer from "../Footer/Footer";
import BottomNavigationBar from "../BottomNavigationBar";
import { useLocation } from "react-router-dom";

function Blog({ homeflag }) {
  const [openModal, setOpenModal] = useState(false);
  const [allBlogData, setAllBlogData] = useState([]);
  const [displayedLatestNews, setDisplayedLatestNews] = useState(4);
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [edit, setEdit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [editblog, setEditBlog] = useState(null);
  const [blogedit, setBlogEdit] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [showmore1, setShowMore1] = useState(false);
  const [showmore2, setShowMore2] = useState(false);
  const [showmore3, setShowMore3] = useState(false);
  const [loggedInuser, setLoggedInUser] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedBlogForEdit, setSelectedBlogForEdit] = useState(null);

  const [blogDescription, setBlogDescription] = useState("");
  const [numCharactersToShow, setNumCharactersToShow] = useState(500);
  const [videoData, setVideosData] = useState([]);
  const [played, setPlayed] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [newData, setNewsData] = useState([]);
  const location = useLocation();
  /**styled of Table */
  function createData(matchname, teamname) {
    return { matchname, teamname };
  }

  const toggleDescription = () => {
    setNumCharactersToShow(numCharactersToShow + 500);
  };

  const formatDescription = (description) => {
    const formattedText = description.replace(/<[^>]*>?/gm, ""); // Remove HTML tags
    return formattedText.slice(0, numCharactersToShow);
  };

  const handleShare1 = async (blog) => {
    const blogUrl = `${window.location.origin}/blog?blogId=${blog._id}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: blog.title,
          url: blogUrl,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(blogUrl);
        alert("Blog URL copied to clipboard!");
      } catch (error) {
        console.error("Failed to copy URL:", error);
      }
    }
  };

  const handleScrollToSection = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href").slice(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const theme = useTheme();
  const isMobilenew = useMediaQuery(theme.breakpoints.down("sm"));

  const rows = [
    createData("Venue", `${selectedNews?.venue}`),
    createData("Date & Time	", `${selectedNews?.date}`),
    createData("Live Broadcast and Streaming Details", `${selectedNews?.live}`),
  ];

  const StyledTableCell = styled(TableCell)({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0077b6",
      color: "#ffffff",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "50px",
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Assuming 768px is your mobile breakpoint
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchPlaylistVideos = async () => {
      const playlistId = "PLSUatChf34_VgcSDo2kk-ZfbNzOJrBG9U"; // Replace with your playlist ID
      const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
      const maxResults = 50;

      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=${maxResults}&playlistId=${playlistId}&key=${apiKey}`
        );

        const fetchedVideos = response.data.items.map((item) => ({
          id: item.snippet.resourceId.videoId,
          title: item.snippet.title,
          videoId: item.snippet.resourceId.videoId,
          publishedAt: item.snippet.publishedAt,
        }));
        fetchedVideos.sort(
          (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
        );
        setVideosData(fetchedVideos);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    const fetchLatestNews = async () => {
      try {
        const response = await axios.get(
          `https://newsapi.org/v2/top-headlines?country=in&category=sports&sortBy=publishedAt&apiKey=${process.env.REACT_APP_NEW_APIKEY}`
        );

        setNewsData(response?.data.articles);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPlaylistVideos();
    fetchLatestNews();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const blogId = query.get("blogId");

    if (blogId && allBlogData.length > 0) {
      const blog = allBlogData.find((blog) => blog._id === blogId);
      if (blog) {
        handleCardClick(blog);
      }
    }
  }, [location.search, allBlogData]);

  const handleDelete = async (editblog) => {
    // Show confirmation dialog
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this post?"
    );

    if (confirmDelete) {
      try {
        const { data } = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/api/delete/${editblog}`
        );

        setIsSuccess(true);
        setSuccessMessage("Blog post Delete Sucessfully");
      } catch (error) {
        console.error("Error deleting post:", error);
        setHasError(true);
        setErrorMessage("Something went wrong");
      }
    } else {
      console.log("Deletion canceled by user");
    }
  };

  const handleVideoClick = () => {
    setPlayed(true);
  };

  const { user } = ChatState();

  const { _id } = user ?? "";

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setLoggedInUser(userData);
    }
  }, []);

  const handleCloseModal = () => {
    console.log("Closing modal"); // Debugging statement
    setShowModal(false);
    setOpenModal(false);
  };

  const handleCardClick = (blog) => {
    console.log("this is blog", blog);
    const description = blog.description;
    const sanitizedDescription = sanitizeHtml(description);
    setBlogDescription(sanitizedDescription);
    const words = description?.split(/\s+/);

    let numWords = Math.floor(words.length / 3); // Approximate number of words per part
    let firstPart = words.slice(0, numWords).join(" ");
    let secondPart = words.slice(numWords, numWords * 2).join(" ");
    let thirdPart = words.slice(numWords * 2).join(" ");

    let firstImage = blog.imageUrls[0] || "";
    let secondImage = blog.imageUrls[1] || "";
    let thirdImage = blog.imageUrls[2] || "";
    console.log("videourl", blog?.videoUrl);
    const videoIdMatch = blog?.videoUrl.match(
      /(?:live\/|embed\/|v\/|watch\?v=|.*[?&]v=)([^\s?&]+)/
    );
    const videoId = videoIdMatch && videoIdMatch[1];

    setSelectedNews({
      title: blog?.title,
      description: blog?.description,
      firstPart,
      firstImage,
      secondPart,
      secondImage,
      thirdPart,
      thirdImage,
      videoId,
      videoUrl: blog.videoUrl,
      matchtitle: blog.matchtitle,
      venue: blog.venue,
      live: blog.live,
      date: blog.date,
      posted: blog?.createdAt,
      publisedBy: blog?.user.email,
      WeatherReport: blog?.weatherreport,
      PitchReport: blog?.pitchreport,
      TeamNews: blog?.teamnews,
      PredictionResult: blog?.predictionresult,
      PlayerName: blog?.playerNames,
      TeamA: blog?.TeamA,
      TeamB: blog?.TeamB,
      Team1Name: blog?.teamaname,
      Team2Name: blog?.teambname,
      Squad: blog?.squad,
    });

    setShowModal(true);
  };

  const [showMore, setShowMore] = useState(false);

  const handleReadMore = () => {
    setShowMore(true);
  };
  const handleReadMoreThirdPart = () => {
    setShowMore3(true);
  };

  const handleReadMoreSecondPart = () => {
    setShowMore2(true);
  };

  const handleReadMoreFirstPart = () => {
    setShowMore1(true);
  };

  const fetchAllBLog = async (req, res) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/fetch`
      );

      if (data) {
        const sortedBlogs = data.sort((a, b) => {
          const latestTimeA = new Date(b.updated_at || b.created_at);
          const latestTimeB = new Date(a.updated_at || a.created_at);
          return latestTimeA - latestTimeB;
        });

        setAllBlogData(sortedBlogs);
        setLoading(false);
        setIsSuccess(true);
        setSuccessMessage("Blog fetch successfully");
        setTimeout(() => {
          setIsSuccess(false);
          setSuccessMessage("");
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(error);
    }
  };

  const navigate = useNavigate();

  const handleback = () => {
    navigate("/more");
  };
  const handleUpdate = (blogId) => {
    setEditModalOpen(true);
    setSelectedBlogForEdit(blogId);
  };

  useEffect(() => {
    fetchAllBLog();
  }, []);

  const handlevideoClick = (video) => {
    setSelectedVideo(video);
    setShowModal(true);
  };

  const handleReadMore1 = (description) => {
    setModalContent(description);
    setShowModal(true);
  };

  const handleShare = (blog) => {
    const shareUrl = `${window.location.origin}${window.location.pathname}?blogId=${blog._id}`;
    if (navigator.share) {
      navigator
        .share({
          title: blog.title,
          text: blog.description,
          url: shareUrl,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      // Fallback if navigator.share is not supported
      alert(`Share this link: ${shareUrl}`);
    }
  };

  return (
    <div
      style={{
        margin: "20px",
        overflowY: "auto",
        marginTop: isMobile ? "10%" : "4%",
      }}
    >
      {hasError && (
        <ErrorNotifier {...{ message: errorMessage, setHasError }} />
      )}
      {isSuccess && (
        <SuccessNotifier {...{ message: successMessage, setIsSuccess }} />
      )}

      {!homeflag && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <ArrowBackIcon onClick={handleback} sx={{ cursor: "pointer" }} />
          <Typography variant="h6">Our BLogs</Typography>
        </Box>
      )}

      <EditBlogModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        blogData={selectedBlogForEdit}
      />
      <Box sx={{ width: "100%", justifyContent: "center", display: "flex" }}>
        <Typography variant="h5" sx={{ color: "black", padding: "10px" }}>
          Featured Blog
        </Typography>
      </Box>
      {loading ? (
        <circularProgressClasses />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px",
          }}
        >
          {loggedInuser && loggedInuser.role === "admin" ? (
            <Button
              sx={{ backgroundColor: "red" }}
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
            >
              Create Blog
            </Button>
          ) : (
            <></>
          )}
        </Box>
      )}
      <AddBlogModal
        open={openModal}
        onClose={handleCloseModal}
        edit={edit}
        editblog={editblog}
      />
      <Grid container spacing={4}>
        {allBlogData.map((blog, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                Width: "100%",
                marginY: 5,
                height: "50vh",
                alignItems: "center",
                padding: "10px",

                border: "2px solid #252422",
                position: "relative",
                "@media (min-width: 768px)": {
                  // Adjusting padding for laptop size
                  maxWidth: 345,
                },
                transition: "transform 0.3s ease-in-out",
                // overflowY: "hidden",
                "&:hover": {
                  transform: "scale(1.005)",
                  overflowY: "auto",
                  overflowY: "auto",
                },
              }}
            >
              <CardActionArea onClick={() => handleCardClick(blog)}>
                {/* Assuming the first image URL is used as the preview image */}
                <CardMedia
                  component="img"
                  width="100%"
                  height="200vh"
                  image={blog.imageUrls[0]}
                  alt="Blog Preview"
                  sx={{
                    borderBottom: "2px solid #252422",
                    borderRadius: "4px",
                    objectFit: "cover",
                  }}
                />
                <CardContent
                  sx={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    sx={{ fontWeight: "600", color: "#03045e", width: "100%" }}
                    component="div"
                  >
                    {blog?.title?.length > 30
                      ? `${blog.title.slice(0, 30)}...`
                      : blog?.title}
                  </Typography>
                  {/* <Box
                    sx={{
                      width: "100%",

                      textAlign: "center",
                      wordWrap: "break-word",
                      overflowY: "auto",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        color: "#343a40",
                        fontSize: "20px",
                        marginX: "20px",
                        fontFamily: "roboto",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            blog?.description?.length > 100
                              ? blog?.description.slice(0, 100) + "..."
                              : blog.description,
                        }}
                      />
                    </Typography>
                    {blog?.description?.length > 100 && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleReadMore(blog.description)}
                      >
                        Read More
                      </Button>
                    )}
                  </Box> */}

                  {loggedInuser?._id === blog?.user._id ? (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        display: "flex",
                        flexDirection: "row",
                        zIndex: 1,

                        transition: "opacity 0.3s ease-in-out", // Add transition effect
                      }}
                    >
                      <IconButton
                        sx={{ color: "#F6F5F2" }}
                        onClick={() => handleUpdate(blog._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        sx={{
                          color: "#ffffff",
                          backgroundColor: "black",
                          borderRadius: "50%",
                        }}
                        onClick={() => handleDelete(blog._id)}
                      >
                        <DeleteIcon />
                      </IconButton>

                      <IconButton
                        sx={{
                          color: "#ffffff",
                          backgroundColor: "black",
                          borderRadius: "50%",
                        }}
                        onClick={() => handleShare(blog)}
                      >
                        <ShareIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        display: "flex",
                        flexDirection: "row",
                        zIndex: 1,

                        transition: "opacity 0.3s ease-in-out", // Add transition effect
                      }}
                    >
                      <IconButton
                        sx={{ color: "#ffffff" }}
                        onClick={() => handleShare(blog)}
                      >
                        <ShareIcon />
                      </IconButton>
                    </Box>
                  )}
                </CardContent>
              </CardActionArea>
              {/* Assuming user information is present */}
              {blog.user && (
                <Box
                  sx={{
                    zIndex: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    padding: "10px",
                  }}
                >
                  {/* <Typography variant="subtitle1" color="#03045e">
                    {blog.user.email}
                  </Typography> */}
                </Box>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100vh",
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        <Card
          sx={{ backgroundColor: "black", color: "white", textAlign: "center" }}
        >
          <Navbar />

          <CardContent
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              padding: "40px",
              marginTop: "50px",
            }}
          >
            <Box sx={{ width: isMobilenew ? "100%" : "65%" }}>
              {/* box that contain heading,image,preview,match info,weather report pitch report */}
              <Typography
                variant="h4"
                component="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#fff",
                  // marginBottom: "5px",
                  textAlign: "start",
                  fontSize: isMobilenew ? "1rem" : "1.8rem",
                }}
              >
                {selectedNews?.title}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography sx={{ fontSize: isMobile ? "5px" : "10px" }}>
                  Date & Time :{selectedNews?.posted}
                </Typography>
                {/* <Typography sx={{ fontSize: "10px" }}>
                  Published By :{selectedNews?.publisedBy}
                </Typography> */}
              </Box>
              <Box sx={{ objectFit: "contain", padding: "30px" }}>
                <img
                  src={selectedNews?.firstImage}
                  alt="blogimage"
                  style={{
                    height: "auto",
                    width: "50vw",
                  }}
                />
              </Box>

              {/* table of content */}
              <Box
                sx={{
                  textAlign: "justify",
                  padding: "10px",
                  border: "2px solid white",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", marginBottom: "10px" }}
                >
                  Table Of Content
                </Typography>
                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                  <li>
                    <Typography
                      component="a"
                      href="#preview"
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        textDecoration: "none",
                      }}
                      onClick={handleScrollToSection}
                    >
                      Preview
                    </Typography>{" "}
                  </li>
                  <li>
                    <Typography
                      component="a"
                      href="#Match_info"
                      variant="body1"
                      sx={{
                        fontWeight: "600",
                        color: "white",
                        textDecoration: "none",
                      }}
                      onClick={handleScrollToSection}
                    >
                      Match Info
                    </Typography>{" "}
                  </li>
                  <li>
                    <Typography
                      component="a"
                      href="#weather_report"
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        textDecoration: "none",
                      }}
                      onClick={handleScrollToSection}
                    >
                      Weather Report
                    </Typography>{" "}
                  </li>
                  <li>
                    <Typography
                      component="a"
                      href="#pitch_report"
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        textDecoration: "none",
                      }}
                      onClick={handleScrollToSection}
                    >
                      Pitch Report
                    </Typography>{" "}
                  </li>
                  <li>
                    <Typography
                      component="a"
                      href="#playing_11"
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        textDecoration: "none",
                      }}
                      onClick={handleScrollToSection}
                    >
                      Playing 11
                    </Typography>{" "}
                  </li>
                  <li>
                    <Typography
                      component="a"
                      href="#prediction_result"
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        textDecoration: "none",
                      }}
                      onClick={handleScrollToSection}
                    >
                      Prediction Result
                    </Typography>{" "}
                  </li>
                  <li>
                    <Typography
                      component="a"
                      href="#team_news"
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        textDecoration: "none",
                      }}
                      onClick={handleScrollToSection}
                    >
                      Team news
                    </Typography>{" "}
                  </li>
                  <li>
                    <Typography
                      component="a"
                      href="#squad"
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        color: "white",
                        textDecoration: "none",
                      }}
                      onClick={handleScrollToSection}
                    >
                      Squad
                    </Typography>{" "}
                  </li>
                </ul>
              </Box>

              {/* preview */}
              <Box
                id="preview"
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  Preview
                </Typography>

                <p dangerouslySetInnerHTML={{ __html: blogDescription }} />
              </Box>
              {/* match info section */}
              <Box
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                  border: "2px solid white",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  id="Match_info"
                  variant="h5"
                  sx={{ marginBottom: "10px" }}
                >
                  Match Info
                </Typography>
                <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                  <li>
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{ fontWeight: "bold" }}
                    >
                      Match Title :
                    </Typography>{" "}
                    {selectedNews?.matchtitle}
                  </li>
                  <li>
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{ fontWeight: "bold" }}
                    >
                      Vanue :
                    </Typography>{" "}
                    {selectedNews?.venue}
                  </li>

                  <li>
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{ fontWeight: "bold" }}
                    >
                      Date :
                    </Typography>{" "}
                    {selectedNews?.date}
                  </li>
                </ul>
              </Box>

              {/* weather report */}

              {selectedNews?.WeatherReport && (
                <Box
                  id="weather_report"
                  sx={{
                    textAlign: "justify",
                    padding: "15px",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                    color: "#fff", // White text for better contrast
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600", marginBottom: "10px" }}
                  >
                    Weather Report
                  </Typography>
                  <Typography>{selectedNews?.WeatherReport}</Typography>
                </Box>
              )}

              {/* pitch report */}

              {selectedNews?.PitchReport && (
                <Box
                  id="pitch_report"
                  sx={{
                    textAlign: "justify",
                    padding: "15px",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                    color: "#fff", // White text for better contrast
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600", marginBottom: "10px" }}
                  >
                    Pitch Report
                  </Typography>
                  <Typography>{selectedNews?.PitchReport}</Typography>
                </Box>
              )}

              {/* playing 11 */}
              {selectedNews?.TeamA && selectedNews.Team1Name && (
                <Box
                  id="playing_11"
                  sx={{
                    textAlign: "justify",
                    padding: "15px",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                    color: "#fff",
                    border: "2px solid white",
                    marginBottom: "10px", // White text for better contrast
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600", marginBottom: "10px" }}
                  >
                    Playing 11/Probably Playing 11 {selectedNews?.Team1Name}
                  </Typography>
                  <Typography>{selectedNews?.TeamA}</Typography>
                </Box>
              )}
              {selectedNews?.TeamB && selectedNews?.Team2Name && (
                <Box
                  sx={{
                    textAlign: "justify",
                    padding: "15px",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                    color: "#fff",
                    border: "2px solid white",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600", marginBottom: "10px" }}
                  >
                    Playing 11/Probably Playing 11 {selectedNews?.Team2Name}
                  </Typography>
                  <Typography>{selectedNews?.TeamB}</Typography>
                </Box>
              )}

              {/* prediction result */}
              {selectedNews?.PredictionResult && (
                <Box
                  id="prediction_result"
                  sx={{
                    textAlign: "justify",
                    padding: "15px",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                    color: "#fff", // White text for better contrast
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "10px", fontWeight: "600" }}
                  >
                    Prediction Result
                  </Typography>
                  <Typography>{selectedNews?.PredictionResult}</Typography>
                </Box>
              )}

              {/* Team news */}

              {selectedNews?.TeamNews && (
                <Box
                  id="team_news"
                  sx={{
                    textAlign: "justify",
                    padding: "15px",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                    color: "#fff", // White text for better contrast
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "10px", fontWeight: "600" }}
                  >
                    Team News
                  </Typography>
                  <Typography>{selectedNews?.TeamNews}</Typography>
                </Box>
              )}

              {/* squad */}
              {selectedNews?.Squad && (
                <Box
                  id="squad"
                  sx={{
                    textAlign: "justify",
                    padding: "15px",
                    borderRadius: "10px", // Rounded corners
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                    color: "#fff", // White text for better contrast
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "10px", fontWeight: "600" }}
                  >
                    Squad
                  </Typography>
                  <Typography>{selectedNews?.Squad}</Typography>
                </Box>
              )}

              {/* video player */}

              <Box
                id="squad"
                sx={{
                  textAlign: "justify",
                  padding: "15px",
                  borderRadius: "10px", // Rounded corners
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)", // Drop shadow for depth
                  color: "#fff",
                  height: "20rem",
                }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${selectedNews?.videoId}`}
                  // title={video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  // style={{ border: 0, boxSizing: "content-box" }}
                ></iframe>
              </Box>

              {isMobilenew && (
                <Box
                  sx={{
                    width: "100%",
                    padding: "10px",
                    alignItems: "center",
                    textAlign: "center",
                    display: "grid",
                    justifyItems: "center",
                  }}
                >
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <Typography
                      variant="heading"
                      sx={{
                        marginTop: "30px",

                        fontSize: isMobilenew ? "0.8rem" : "1.8rem",
                        fontWeight: "600",
                      }}
                    >
                      Trending Video
                    </Typography>
                  </Box>
                  {videoData.slice(0, 3).map((video, index) => (
                    <Box
                      sx={{
                        width: isMobilenew ? "100%" : "70%",
                        height: isMobilenew ? "7rem" : "10rem",
                        margin: "20px",
                      }}
                    >
                      <iframe
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${video.videoId}`}
                        title={video.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        // style={{ border: 0, boxSizing: "content-box" }}
                      ></iframe>
                    </Box>
                  ))}

                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="heading"
                      sx={{
                        marginTop: "30px",
                        fontSize: isMobilenew ? "0.8rem" : "1.8rem",
                        fontWeight: "600",
                      }}
                    >
                      Trending News
                    </Typography>
                  </Box>

                  {newData.slice(0, 3).map((result, index) => (
                    <Box
                      sx={{
                        width: isMobilenew ? "100%" : "75%",
                        height: isMobilenew ? "7rem" : "10rem",
                        margin: "20px",
                        marginBottom: isMobilenew ? "40px" : "20px",
                      }}
                    >
                      <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image={result?.urlToImage}
                          alt="green iguana"
                        ></CardMedia>
                      </Card>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            {!isMobilenew && (
              <Box sx={{ width: "35%", padding: "10px" }}>
                <Box sx={{ width: "100%", textAlign: "justify" }}>
                  <Typography
                    variant="heading"
                    sx={{
                      marginTop: "30px",

                      fontSize: isMobilenew ? "0.8rem" : "1.8rem",
                      fontWeight: "600",
                    }}
                  >
                    Trending Video
                  </Typography>
                </Box>
                {videoData.slice(0, 3).map((video, index) => (
                  <Box
                    sx={{
                      width: isMobilenew ? "100%" : "70%",
                      height: isMobilenew ? "7rem" : "10rem",
                      margin: "20px",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${video.videoId}`}
                      title={video.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      // style={{ border: 0, boxSizing: "content-box" }}
                    ></iframe>
                  </Box>
                ))}

                <Box sx={{ width: "100%", textAlign: "justify" }}>
                  <Typography
                    variant="heading"
                    sx={{
                      marginTop: "30px",
                      fontSize: isMobilenew ? "0.8rem" : "1.8rem",
                      fontWeight: "600",
                    }}
                  >
                    Trending News
                  </Typography>
                </Box>

                {newData.slice(0, 3).map((result, index) => (
                  <Box
                    sx={{
                      width: isMobilenew ? "100%" : "75%",
                      height: isMobilenew ? "7rem" : "10rem",
                      marginBottom: isMobilenew ? "40px" : "20px",
                    }}
                  >
                    <Card>
                      <CardMedia
                        component="img"
                        height="140"
                        image={result?.urlToImage}
                        alt="green iguana"
                      ></CardMedia>
                    </Card>
                  </Box>
                ))}
              </Box>
            )}
          </CardContent>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{
              marginY: 8,
              width: "200px",
              height: "50px",
              backgroundColor: "red",
            }}
          >
            Close
          </Button>

          {isMobilenew ? <BottomNavigationBar /> : <Footer />}
        </Card>
      </Modal>
    </div>
  );
}

export default Blog;
