import React, { useState, useEffect } from "react";
import {
  Modal,
  Card,
  Box,
  Typography,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  LinearProgress,
  Paper,
  IconButton,
} from "@mui/material";
import "./Poll.css"; // Ensure the CSS file is correctly imported
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import axios from "axios";

const Poll = ({ blogId }) => {
  const [poll, setPoll] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [percentages, setPercentages] = useState([]);
  const [votes, setVotes] = useState(0);
  const [formData, setFormData] = useState({
    title: "",
    question: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
  });

  const fetchedLoggedInUser = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setLoggedInUser(userData);
    }
  };

  useEffect(() => {
    fetchedLoggedInUser();
    const fetchLatestPoll = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/polls/latest/${blogId}`
        );
        setPoll(response.data);
        setVotes(Math.floor(Math.random() * 1000)); 
      } catch (error) {
        console.error("Error fetching latest poll:", error);
      }
    };

    fetchLatestPoll();
  }, [blogId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/polls/create`,
        {
          title: formData.title,
          question: formData.question,
          options: [
            formData.optionA,
            formData.optionB,
          ],
          blog: blogId,
        }
      );
      setPoll(response.data);
      setShowForm(false);
    } catch (error) {
      console.error("Error creating poll:", error);
    }
  };

  const handleVoteSubmit = () => {
    if (!selectedOption) return;
    setShowResults(true);
    generateRandomPercentages();
  };

  const generateRandomPercentages = () => {
    const total = 100;
    const percentages = [];
    let sum = 0;

    for (let i = 0; i < poll.options.length - 1; i++) {
      const randomPercentage = Math.floor(Math.random() * (total - sum) / 2);
      percentages.push(randomPercentage);
      sum += randomPercentage;
    }

    percentages.push(total - sum);
    setPercentages(percentages);
  };

  const togglePollState = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/polls/toggle/${poll._id}`
      );
      setPoll(response.data);
    } catch (error) {
      console.error("Error toggling poll state:", error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      {loggedInUser && loggedInUser.role === "admin" && (
        <>
          <Button variant="contained" onClick={() => setShowForm(true)}>
            Add Poll Question
          </Button>
          {poll && (
            <Button
              variant="contained"
              color={poll.isActive ? "secondary" : "primary"}
              onClick={togglePollState}
              sx={{ marginLeft: 2 }}
            >
              {poll.isActive ? "Deactivate Poll" : "Activate Poll"}
            </Button>
          )}
        </>
      )}

      {poll && (
        <Card sx={{ padding: 2, marginBottom: 2, backgroundColor: '#f5f5f5' }}>
          {/* <div className="floating-semi-circle">
            {votes}
          </div> */}
          <Box sx={{ backgroundColor: '#3f51b5', color: 'white', padding: 2 }}>
          
          <Typography variant="body2" sx={{ marginRight: 2 }}>
                {votes} people already voted
              </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {poll.title}
            </Typography>
            <Typography variant="body2">
              {new Date(poll.createdAt).toLocaleDateString()} {new Date(poll.createdAt).toLocaleTimeString()}
            </Typography>
          </Box>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
              {poll.question}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                {poll.options.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={<Radio />}
                    label={
                      <Paper sx={{ padding: '5px 10px', backgroundColor: '#f0f0f0',width:"150px" }}>
                        {option}
                      </Paper>
                    }
                    disabled={showResults}
                  />
                ))}
              </RadioGroup>
              {!showResults && (
              <Button
                variant="contained"
                onClick={handleVoteSubmit}
                sx={{ marginTop: 2, backgroundColor: '#3f51b5', color: 'white' }}
              >
                Submit
              </Button>
            )}
            </FormControl>
           

            {showResults && (
              <Box sx={{ marginTop: 4 }}>
                {poll.options.map((option, index) => (
                  <Box key={index} sx={{ marginBottom: 2 }}>
                    <Typography variant="body1">
                      {option}: {percentages[index]}%
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={percentages[index]}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <IconButton
                    sx={{ marginRight: 2 }}
                    color="primary"
                    href="https://chat.whatsapp.com/E90I8wg7e392h418Dgw230"
                    target="_blank"
                  >
                    <WhatsAppIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    href="https://telegram.me/+HWaRqPIT-ltlYWE1"
                    target="_blank"
                  >
                    <TelegramIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
        </Card>
      )}

      <Modal open={showForm} onClose={() => setShowForm(false)}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            marginTop: "10%",
            maxWidth: "500px",
          }}
        >
          <Typography variant="h6">Add Poll Question</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Title"
              name="title"
              fullWidth
              margin="normal"
              value={formData.title}
              onChange={handleInputChange}
            />
            <TextField
              label="Question"
              name="question"
              fullWidth
              margin="normal"
              value={formData.question}
              onChange={handleInputChange}
            />
            <TextField
              label="Option A"
              name="optionA"
              fullWidth
              margin="normal"
              value={formData.optionA}
              onChange={handleInputChange}
            />
            <TextField
              label="Option B"
              name="optionB"
              fullWidth
              margin="normal"
              value={formData.optionB}
              onChange={handleInputChange}
            />
           
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default Poll;
